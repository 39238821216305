import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Select from "shared/Select/Select";
import { BEARER_TOKEN } from "../../../src/store/config";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import { useCounterStore } from "store/auth";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";

import DataTable, { createTheme } from "react-data-table-component";
import './style-table.css'

import Modal from 'react-modal';
Modal.setAppElement('#root');

interface RowData {
  item: string;
  typeOfMovement: string;
  concept: string;
  receipt: string;
  fileName: string;
  amount: string;
  status: string;
  observation: string;
  creationTime: string;
}

const Commissions = () => {
  const [totalCommission, setTotalCommission] = useState(0);
  const [historicalCommission, setHistoricalCommission] = useState(0);
  const [pendingPaymentCommission, setPendingPaymentCommission] = useState(0);
  const [patronBonus, setPatronBonus] = useState(0);
  const [retirementBonus, setRetirementBonus] = useState(0);
  const [rtiBonus, setRtiBonuss] = useState(0);
  const [detailPatronBonus, setDetailPatronBonus] = useState(null);
  const [detailRetirementBonus, setDetailRetirementBonus] = useState(null);
  //---------------------------------------------------------------
  const [options, setOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("0");
  const [showNumberComisiónHistórica, setShowNumberComisiónHistórica] =
    useState(true);
  const [showNumberComisiónActual, setShowNumberComisiónActual] =
    useState(true);
  const [user, setUser] = useState({}); // Usuario obtenido de localStorage
  //---------------------------------------------------------------
  const dataIsLoggedIn = useCounterStore((state) => state.dataIsLoggedIn);
  const valor = localStorage.getItem('USER_AUTH') ?? 'valor_por_defecto';
  //---------------------------------------------------------------
  const [bank, setBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [cciAccountNumber, setCciAccountNumber] = useState("");
  const [ruc, setRuc] = useState("");
  const [holderName, setHolderName] = useState("");
  //---------------------------------------------------------------
  const [isCommissionRegistered, setIsCommissionRegistered] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  //---------------------------------------------------------------
  useEffect(() => {
    // Verificar si el usuario está autenticado, de lo contrario, redirigir al Login
    if (valor === "valor_por_defecto") {
      window.location.href = '/login';
    }
  }, [dataIsLoggedIn]);

  const handleCountComisiónHistóricaUpEnd = () => {
    setTimeout(() => {
      setShowNumberComisiónHistórica(false);
      setTimeout(() => {
        setShowNumberComisiónHistórica(true);
        setTimeout(() => {
          setShowNumberComisiónHistórica(false);
          setTimeout(() => {
            setShowNumberComisiónHistórica(true);
          }, 300);
        }, 300);
      }, 300);
    }, 100);
  };
  const handleCountComisiónActualUpEnd = () => {
    setTimeout(() => {
      setShowNumberComisiónActual(false);
      setTimeout(() => {
        setShowNumberComisiónActual(true);
        setTimeout(() => {
          setShowNumberComisiónActual(false);
          setTimeout(() => {
            setShowNumberComisiónActual(true);
          }, 300);
        }, 300);
      }, 300);
    }, 100);
  };

  const userData = JSON.parse(localStorage.getItem("USER_AUTH") || "{}");
  useEffect(() => {
    const fetchDataNetworkPeriodForMLM = async () => {
      try {
        const response = await axios.get(
          "https://api.yosoymitosis.com/v1/ComissionPeriod/GetComissionPeriodForComission",
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        const options = response.data.data;
        // Encontrar el periodo mayor
        const maxPeriod = Math.max(...options.map((option: any) => option.commissionPeriodId));
        setSelectedPeriod(maxPeriod.toString()); // Seleccionar el periodo mayor
        //options.sort((a: any, b: any) => a.commissionPeriodId - b.commissionPeriodId);
        setOptions(options);
        // Ejecutar GetCommissionByUser con el periodo mayor
        fetchDataCommissionByUser(maxPeriod.toString());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataNetworkPeriodForMLM();

    const fetchDataCommissionByUser = async (maxPeriod: any) => {
      try {
        const response = await axios.post(
          'https://api.yosoymitosis.com/v1/UserCommission/GetCommissionByUser',
          {
            userId: userData.userId,
            commissionPeriodId: maxPeriod,
          },
          {
            headers: {
              Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibWl0b3NpekFwaSIsInBhc3N3b3JkIjoiQG1pdG9zaXo5NiIsImF1ZCI6IkZyb250TWl0b3NpeiJ9.PjRxNwguwkC6I_Qtlo6XLy1686QFyU5L2QroleKQAX0',
            },
          }
        );
        setTotalCommission(response.data.data.totalCommission);
        setHistoricalCommission(response.data.data.historicalCommission);
        setPendingPaymentCommission(response.data.data.commissionToBeCollected);
        setPatronBonus(response.data.data.patronBonus);
        setRetirementBonus(response.data.data.retirementBonus);
        setRtiBonuss(response.data.data.rtiBonus);
        setDetailPatronBonus(response.data.data.detailPatronBonus);
        setDetailRetirementBonus(response.data.data.detailRetirementBonus);
      } catch (error) {
        console.error('Error al obtener el bono de patrocinio:', error);
      }
    };

    const fetchDataUser = async () => {
      try {
        const response = await axios.post(
          "https://api.yosoymitosis.com/v1/User/GetUserProfile",
          {
            userId: USER_AUTH.userId,
          },
          {
            headers: {
              'Authorization': `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        setBank(response.data.data.bank);
        setAccountNumber(response.data.data.bankAccount);
        setCciAccountNumber(response.data.data.interbankAccount);
        setRuc(response.data.data.ruc);
        setHolderName(response.data.data.holderName);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchDataUser();
  }, [isCommissionRegistered]);

  const fetchDataCommissionByUser = async (commissionPeriodId: any) => {
    if (selectedPeriod === "0") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'Debe seleccionar un periodo.',
      });
    } else {
      try {
        const response = await axios.post(
          'https://api.yosoymitosis.com/v1/UserCommission/GetCommissionByUser',
          {
            userId: userData.userId, // Obtener el userId del localStorage
            commissionPeriodId: commissionPeriodId,
          },
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        // Aquí actualizas tus estados con la información obtenida
        setTotalCommission(response.data.data.totalCommission);
        setHistoricalCommission(response.data.data.historicalCommission);
        setPendingPaymentCommission(response.data.data.commissionToBeCollected);
        setPatronBonus(response.data.data.patronBonus);
        setRetirementBonus(response.data.data.retirementBonus);
        setRtiBonuss(response.data.data.rtiBonus);
        setDetailPatronBonus(response.data.data.detailPatronBonus);
        setDetailRetirementBonus(response.data.data.detailRetirementBonus);
      } catch (error) {
        console.error('Error al obtener el bono de patrocinio:', error);
      }
    }
  };

  const handleUpdateButtonClick = () => {
    fetchDataCommissionByUser(selectedPeriod);
  };

  const totalAmountBonoPatrocinio = detailPatronBonus
    ? Object.keys(detailPatronBonus)
      .filter((key) => key !== 'detailPatronBonusId' && key !== 'commissionId')
      .reduce((acc, key) => acc + (detailPatronBonus[key] || 0), 0)
    : 0;

  const totalAmountBonoJubilación = detailRetirementBonus
    ? Object.keys(detailRetirementBonus)
      .filter((key) => key !== 'detailRetirementBonusId' && key !== 'commissionId')
      .reduce((acc, key) => acc + (detailRetirementBonus[key] || 0), 0)
    : 0;

  //-----------------------------------------TABLE---------------------------------------------------------

  // const [records, setRecords] = useState<RowData[]>([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setData(data)
  //     setLoading(false);
  //   }, 2000);
  //   return () => clearTimeout(timeout)
  // }, [])

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const searchValue = e.target.value.toLowerCase();
  //   if (searchValue === '') {
  //     fetchUsers(1);
  //   } else {
  //     const filterRecords = data.filter((record: any) =>
  //       record.first_name.toLowerCase().includes(searchValue)
  //     );
  //     setData(filterRecords);
  //   }
  // };

  // const [data, setData] = useState([]);
  // const [perPage, setPerPage] = useState(10);

  // const fetchUsers = async (page: any) => {
  //   setLoading(true);
  //   const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);
  //   setData(response.data.data);
  //   setTotalRows(response.data.total);
  //   setLoading(false);
  // };

  // const handlePageChange = (page: any) => {
  //   fetchUsers(page);
  // };

  // const handlePerRowsChange = async (newPerPage: any, page: any) => {
  //   setLoading(true);

  //   const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);

  //   setData(response.data.data);
  //   setPerPage(newPerPage);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   fetchUsers(1);
  // }, []);

  //---------------------------------------------------------------
  const [orders, setOrders] = useState([]); // Almacenar las comisiones
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Paginación
  const [totalRows, setTotalRows] = useState(0); // Total de registros
  const [ordersPerPage, setOrdersPerPage] = useState(5); // Filas por página

  const [isRegisterComission, setRegisterComission] = useState(false);

  const columns = [
    { name: "Item", selector: (row: RowData) => row.item, sortable: true },
    { name: "Tipo de Movimiento", selector: (row: RowData) => row.typeOfMovement },
    { name: "Concepto", selector: (row: RowData) => row.concept },
    { name: "Fecha", selector: (row: RowData) => new Date(row.creationTime).toLocaleDateString() },
    { name: "Comprobante", selector: (row: RowData) => row.receipt },
    { name: "Documento", selector: (row: RowData) => row.fileName },
    { name: "Monto", selector: (row: RowData) => row.amount },
    { name: "Estado", selector: (row: RowData) => row.status },
    { name: "Observación", selector: (row: RowData) => row.observation },
  ];

  const fetchMovementOfCommitteesForUser = async (pageNumber: number, pageSize: number) => {
    setLoading(true); // Inicia el estado de carga
    try {
      const response = await fetch("https://api.yosoymitosis.com/v1/MovementOfCommittees/GetMovementOfCommitteesForUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${BEARER_TOKEN}`
        },
        body: JSON.stringify({
          number: pageNumber,
          size: pageSize,
          userId: "100006"
        })
      });

      if (response.ok) {
        const data = await response.json();
        // Mapeo de datos para incluir el índice y transformar el objeto
        const formattedOrders = data.data.movementOfCommitteesForUsers.map((movement: any, index: any) => ({
          //item: index + 1, // Asigna el índice
          typeOfMovement: movement.typeOfMovement, // Tipo de Movimiento
          concept: movement.concept, // Concepto
          receipt: movement.receipt, // Comprobante
          fileName: movement.fileName || "", // Documento
          amount: movement.amount, // Monto
          status: movement.status, // Estado
          observation: movement.observation || "", // Observación
          //creationTime: movement.creationTime // Fecha de creación (puedes formatearla en la tabla)
          creationTime: new Date(movement.creationTime).toLocaleDateString(),
        }));
        // Actualiza el estado con los datos formateados
        setOrders(formattedOrders); // Almacena los pedidos en el estado
        // setTotalPages(data.data.totalPages); // Total de páginas
        setTotalRows(data.data.totalPages * pageSize); // Establece el total de registros
      } else {
        console.error("Error fetching orders:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false); // Finaliza el estado de carga
    }
  };
  // useEffect para ejecutar la solicitud cuando cambie la página actual o el número de filas por página
  useEffect(() => {
    fetchMovementOfCommitteesForUser(currentPage, ordersPerPage);
  }, [currentPage, ordersPerPage, isRegisterComission]); // Escucha cambios en currentPage y ordersPerPage

  // Función que se ejecuta al cambiar de página
  const handlePageChange = (page: number) => {
    setCurrentPage(page); // Cambia la página actual
  };

  // Función que se ejecuta al cambiar el número de filas por página
  const handlePerRowsChange = (newPerPage: number) => {
    setOrdersPerPage(newPerPage); // Cambia el número de filas por página
    setCurrentPage(1); // Reinicia a la primera página
  };

  // const paginationComponentOptions = {
  //   rowsPerPageText: 'Filas por página',
  //   rangeSeparatorText: 'de',
  //   selectAllRowsItem: true,
  //   selectAllRowsItemText: 'Todos',
  // };

  // function Loader() {
  //   return <div>
  //     <h1>Cargando</h1>
  //     <h3>Spinner</h3>
  //   </div>
  // }
  //---------------------------------MODAL--------------------------------------------------------
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [img, setImg] = useState<FormData | null>(null); // Estado para la imagen subida
  const [selectedImage, setSelectedImage] = useState<string | null>(null); // Para mostrar la imagen
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null); // Para mostrar el nombre

  const USER_AUTH = JSON.parse(localStorage.getItem("USER_AUTH")!);

  const [monto, setMonto] = useState(0);
  const [concepto, setConcepto] = useState<string>('');

  const [cobro, setCobro] = useState<string>('sinFactura');

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(''); // Limpiar la imagen seleccionada
    setImg(null); // Limpiar la imagen cargada
    setSelectedFileName(''); // Limpiar el nombre del archivo
    setMonto(0); // Restablecer el monto a 0
    setConcepto(''); // Limpiar el concepto
    setCobro('sinFactura'); // Restablecer la opción de cobro
    setModalIsOpen(false);
  };

  const handleMontoChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setMonto(Number(e.target.value));
  };

  const handleConceptoChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setConcepto(e.target.value);
  };

  const handleCobroChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCobro(e.target.value);
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append("imageFile", file);
      setImg(formData);
      setSelectedImage(URL.createObjectURL(file)); // Muestra la imagen cargada en el componente
      setSelectedFileName(file.name);
    } else {
      // Notify the user that only image files are allowed
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Seleccione un archivo de imagen (jpg, jpeg, png, gif).",
      });
      event.target.value = ''; // Clear the input value to allow selecting a new file
    }
  };

  const handleRegisterCommission = async () => {
    // //Obtener del local userDataForAvatarDropdown para la actualización en el zustand
    // const currentUserDataForAvatarDropdowString = localStorage.getItem("userDataForAvatarDropdown");
    // const currentUserDataForAvatarDropdow = currentUserDataForAvatarDropdowString ? JSON.parse(currentUserDataForAvatarDropdowString) : null;
    // if (!currentUserDataForAvatarDropdow) {
    //   // Manejar el caso en que no haya datos en el localStorage
    //   console.error("No se encontraron datos de usuario en el localStorage");
    //   return;
    // }
    //Creamos una copia para actualizar solo algunos campos y mandarlo
    // const updatedUserData = { ...currentUserDataForAvatarDropdow };

    //Si no ha seleccionado una imagen

    const factura = cobro === 'conFactura' ? 'Factura' : 'Sin Factura';
    const responseFileImage = await axios.post(
      "https://api.yosoymitosis.com/v1/MovementOfCommittees/SaveMovementOfCommitteesFile",
      img,
      {
        headers: {
          Authorization: `Bearer ${BEARER_TOKEN}`, // Importa el token de autenticación adecuadamente
        },
      }
    );
    try {
      const response = await axios.post(
        "https://api.yosoymitosis.com/v1/MovementOfCommittees/SaveMovementOfCommittees",
        {
          userId: USER_AUTH.userId,
          concept: concepto,
          receipt: factura,
          fileName: responseFileImage.data.data,
          amount: monto
        },
        {
          headers: {
            Authorization: `Bearer ${BEARER_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Success") {
        // Mostrar mensaje de éxito
        Swal.fire({
          icon: "success",
          title: "Comisión registrada correctamente.",
        });
        setRegisterComission(true);
        closeModal();
        setIsCommissionRegistered(true);
        // Llamar a la función que realiza el scroll al tope de la página
        scrollToTop();
      } else {
        throw new Error("Hubo un error al actualizar el perfil."); //si el mensaje de éxito no se recibe correctamente, se lanzará un error que será capturado por el bloque catch.
      }
    } catch (error) {
      // Si hay un error en la solicitud, mostrar mensaje de error
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al registrar los datos. Por favor, inténtelo de nuevo.",
      });
    }
  };

  const handleRegisterCommissionButtonClick = () => {
    // Aquí puedes agregar validaciones antes de llamar a handleUpdateProfile
    if(monto===0){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Debe ingresar un monto válido.",
      });
      return;
    }
    // Validar que el monto no sea mayor a pendingPaymentCommission
    if (monto > pendingPaymentCommission) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `El monto no puede ser mayor a la comisión pendiente (S/. ${pendingPaymentCommission}).`,
      });
      return;
    }
    // Validación del archivo de imagen
    if (!img) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Debe subir un archivo de imagen (jpeg/png/jpg).",
      });
      return;
    }
    if (ruc == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "RUC inválido, por favor actualice su perfil.",
      });
      return;
    }
    if (accountNumber == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Número de cuenta inválido, por favor actualice su perfil.",
      });
      return;
    }
    handleRegisterCommission();
  };

  return (
    <div className="">
      <Helmet>
        <title>Comisiones</title>
      </Helmet>
      <div className="container w-fit sm:w-full border border-slate-200 dark:border-slate-700 py-4 px-8 sm:p-0 sm:border-none rounded-2xl justify-center flex flex-col sm:flex-row items-center gap-6 sm:gap-8 my-16">
        <div className="flex items-center gap-8">
          <span>Comisión:</span>
          <Select
            className="max-w-[265px] h-[44px]"
            value={selectedPeriod}
            required
            onChange={(e) => setSelectedPeriod(e.target.value)}
          >
            <option value="0">Seleccionar...</option>
            {options.map((option: any) => (
              <option
                key={option.commissionPeriodId}
                value={option.commissionPeriodId}
              >
                {option.periodName}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <ButtonPrimary
            className="sm:pt-2 sm:pb-2 px-6"
            onClick={handleUpdateButtonClick}
          >
            Filtrar
          </ButtonPrimary>
        </div>
      </div>
      <div className="container flex flex-wrap px-20 sm:px-[150px] gap-[50px] justify-around flex-col sm:flex-row mb-20">
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-8">
          <h2 className="flex flex-col items-center text-2xl">
            Comisión Histórica
          </h2>
          <span
            className="text-4xl mt-0 text-[#6200bb]"
            style={{ position: "relative" }}
          >
            S/.&nbsp;
            <motion.span
              initial={{ opacity: 1, scale: 1 }}
              animate={
                showNumberComisiónHistórica
                  ? { opacity: 1, scale: [1, 1.2, 1] }
                  : { opacity: 0 }
              }
              transition={{ duration: 0.3 }}
              style={{ display: "inline-block", position: "relative" }}
            >
              <CountUp
                start={0.5}
                end={historicalCommission}
                duration={2}
                separator=","
                onEnd={handleCountComisiónHistóricaUpEnd}
                decimals={2}
                decimal="."
              />
            </motion.span>
          </span>
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-8">
          <h2 className="flex flex-col items-center text-2xl">
            Comisión del Periodo Actual
          </h2>
          <span
            className="text-4xl mt-0 text-[#6200bb]"
            style={{ position: "relative" }}
          >
            S/.&nbsp;
            <motion.span
              initial={{ opacity: 1, scale: 1 }}
              animate={
                showNumberComisiónActual
                  ? { opacity: 1, scale: [1, 1.2, 1] }
                  : { opacity: 0 }
              }
              transition={{ duration: 0.3 }}
              style={{ display: "inline-block", position: "relative" }}
            >
              <CountUp
                start={0}
                end={totalCommission}
                duration={2}
                separator=","
                onEnd={handleCountComisiónActualUpEnd}
                decimals={2}
                decimal="."
              />
            </motion.span>
          </span>
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-8">
          <h2 className="flex flex-col items-center text-2xl">
            Comisión Pendiente por Cobrar
          </h2>
          <span
            className="text-4xl mt-0 text-[#6200bb]"
            style={{ position: "relative" }}
          >
            S/.&nbsp;
            <motion.span
              initial={{ opacity: 1, scale: 1 }}
              animate={
                showNumberComisiónActual
                  ? { opacity: 1, scale: [1, 1.2, 1] }
                  : { opacity: 0 }
              }
              transition={{ duration: 0.3 }}
              style={{ display: "inline-block", position: "relative" }}
            >
              <CountUp
                start={0}
                end={pendingPaymentCommission}
                duration={2}
                separator=","
                onEnd={handleCountComisiónActualUpEnd}
                decimals={2}
                decimal="."
              />
            </motion.span>
          </span>
        </div>
      </div>
      <div className="container flex flex-col sm:flex-row justify-evenly items-center mt-14 gap-4">
        <div className="h-16 w-64 border-2 border-primaryMitosiz bg-white flex justify-center items-center text-center">
          <h4>
            Bono Patrocinio <br />
            <span>S/. {patronBonus.toFixed(2)}</span>
          </h4>
        </div>
        <div className="h-16 w-64 border-2 border-primaryMitosiz bg-white flex justify-center items-center text-center">
          <h4>
            Bono Jubilación <br />
            <span>S/. {retirementBonus.toFixed(2)}</span>
          </h4>
        </div>
        <div className="h-16 w-64 border-2 border-primaryMitosiz bg-white flex justify-center items-center text-center">
          <h4>
            Bono RTI <br />
            <span>S/. {rtiBonus.toFixed(2)}</span>
          </h4>
        </div>
      </div>
      <div className="container mt-14 flex flex-col lg:flex-row justify-evenly gap-4">
        <div className="min-w-full sm:min-w-[500px]">
          <div
            className="inline-block relative w-full border border-slate-300 dark:border-slate-700 rounded-md text-black bg-white"
            style={{ margin: "37px 0" }}
          >
            <div
              className="float-left p-4 text-white shadow-xl mx-[15px] mt-[-20px] mb-0 rounded-md bg-primaryMitosiz relative z-10 border border-b-[1px] border-[rgba(0,0,0,.125)]"
              data-background-color="cadetblue"
            >
              <i className="las la-address-book text-[40px]"></i>
            </div>
            <h3 className="mt-4 mb-[5px] text-[1.6em]">Bono Patrocinio</h3>
            <div className="py-[15px] px-5 relative mt-6">
              <div className="border-none block w-full md:overflow-x-auto overflow-y-hidden">
                <table className="mb-0 w-auto sm:w-full max-w-full bg-transparent">
                  <thead>
                    <tr>
                      <th className="text-center">Nivel</th>
                      <th className="text-center">Monto </th>
                      <th className="text-center">Porcentaje %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailPatronBonus &&
                      Object.keys(detailPatronBonus)
                        .filter((key) => key !== 'detailPatronBonusId' && key !== 'commissionId')//para que no me liste detailPatronBonusId y commissionId
                        .map((key: any) => (//me mapea o recorre lo restante para rellenarlo en cada tr
                          <tr key={key} className={key % 2 === 0 ? 'bg-[#f9f9f9]' : 'bg-white'}>
                            <td className="text-center py-3 px-2">{key}</td>
                            <td className="text-center py-3 px-2">
                              <span id={`txtNivel${key}`}>{detailPatronBonus[key]}</span>
                            </td>
                            <td className="text-center py-3 px-2"><span id={`txtPORAFI${key}`}>{detailPatronBonus[key] !== 0 ? (detailPatronBonus[key] * 100 / patronBonus).toFixed(2) : 0.00}</span>%</td>
                          </tr>
                        ))}
                    <tr className="">
                      <td colSpan={1}></td>
                      <td className="font-semibold text-lg pt-3 text-right">Monto Total:</td>
                      <td className="td-price flex items-center ml-8">
                        <span id="txtSumaAfiliacion" className="font-semibold text-lg pt-3 w-full text-center">{totalAmountBonoPatrocinio.toFixed(2)}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-full sm:min-w-[500px]">
          <div
            className="inline-block relative w-full border border-slate-300 dark:border-slate-700 rounded-md text-black bg-white"
            style={{ margin: "37px 0" }}
          >
            <div
              className="float-left p-4 text-white shadow-xl mx-[15px] mt-[-20px] mb-0 rounded-md bg-primaryMitosiz relative z-10 border border-b-[1px] border-[rgba(0,0,0,.125)]"
              data-background-color="cadetblue"
            >
              <i className="las la-donate text-[40px]"></i>
            </div>
            <h3 className="mt-4 mb-[5px] text-[1.6em]">Bono Jubilación</h3>
            <div className="py-[15px] px-5 relative mt-6">
              <div className="border-none block w-full md:overflow-x-auto overflow-y-hidden">
                <table className="mb- w-auto sm:w-full max-w-full bg-transparent">
                  <thead>
                    <tr>
                      <th className="text-center">Nivel</th>
                      <th className="text-center">Monto </th>
                      <th className="text-center">Porcentaje %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailRetirementBonus &&
                      Object.keys(detailRetirementBonus)
                        .filter((key) => key !== 'detailRetirementBonusId' && key !== 'commissionId')//para que no me liste detailRetirementBonusId y commissionId
                        .map((key: any) => (//me mapea o recorre lo restante para rellenarlo en cada tr
                          <tr key={key} className={key % 2 === 0 ? 'bg-[#f9f9f9]' : 'bg-white'}>
                            <td className="text-center py-3 px-2">{key}</td>
                            <td className="text-center py-3 px-2">
                              <span id={`txtNivel${key}`}>{detailRetirementBonus[key]}</span>
                            </td>
                            <td className="text-center py-3 px-2"><span id={`txtPORAFI${key}`}>{detailRetirementBonus[key] !== 0 ? (detailRetirementBonus[key] * 100 / retirementBonus).toFixed(2) : 0.00}</span>%</td>
                          </tr>
                        ))}
                    <tr className="">
                      <td colSpan={1}></td>
                      <td className="font-semibold text-lg pt-3 text-right">Monto Total:</td>
                      <td className="td-price flex items-center ml-8">
                        <span id="txtSumaAfiliacion" className="font-semibold text-lg pt-3 w-full text-center">{totalAmountBonoJubilación.toFixed(2)}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container mt-14 mb-4 flex justify-end">
        <button
          onClick={openModal}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Solicitar Cobro
        </button>
      </div> */}

      <div className={modalIsOpen ? "container mt-14 mb-4 inert" : "container mt-14 mb-4"}>
        {/* Contenido principal de tu aplicación */}
        <div className="w-full flex justify-end">
          <button
            onClick={openModal}
            className="px-4 py-2 bg-[#6800D1] text-white rounded hover:bg-[#3A0C7F] transition-colors"
          >
            Solicitar Cobro
          </button>
        </div>
        {/* Aquí iría tu componente de tabla */}
      </div>
      <div className="container mb-14">
        <div className="w-full hidden justify-end mb-0 sm:-mb-[49px] relative z-10">
          <div className="flex items-center gap-2">
            <span>Buscar:</span>
            <input
              type="text"
              // onChange={handleChange}
              placeholder="Buscar un registro"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#653EFF] focus:border-[#653EFF] transition-all duration-300"
            />
          </div>
        </div>
        <table className="min-w-full bg-white">
          <thead>
            <tr style={{background: 'linear-gradient(to right, #653EFF, #6800D1, #3A0C7F)'}}>
              {/* <th className="py-2 px-4 border text-white font-semibold">Item</th> */}
              <th className="py-2 px-4 border text-white font-semibold">Tipo de Movimiento</th>
              <th className="py-2 px-4 border text-white font-semibold">Concepto</th>
              <th className="py-2 px-4 border text-white font-semibold">Fecha</th>
              <th className="py-2 px-4 border text-white font-semibold">Comprobante</th>
              <th className="py-2 px-4 border text-white font-semibold">Documento</th>
              <th className="py-2 px-4 border text-white font-semibold">Monto</th>
              <th className="py-2 px-4 border text-white font-semibold">Estado</th>
              <th className="py-2 px-4 border text-white font-semibold">Observación</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={9} className="text-center py-4">
                  <h1>Cargando...</h1>
                </td>
              </tr>
            ) : (
              orders.map((order: any, index) => (
                <tr key={index} className="text-gray-700">
                  {/* <td className="py-2 px-4 border">{order.item}</td> */}
                  <td className="py-2 px-4 border text-center">{order.typeOfMovement}</td>
                  <td className="py-2 px-4 border text-center">{order.concept}</td>
                  <td className="py-2 px-4 border text-center">{order.creationTime}</td>
                  <td className="py-2 px-4 border text-center">{order.receipt}</td>
                  <td className="py-2 px-4 border text-center">
                    {order.fileName ? (
                      <a href={`https://api.yosoymitosis.com/StaticFiles/MovementOfCommittees/${order.fileName}`} target="_blank" rel="noopener noreferrer">
                        {order.fileName}
                      </a>
                    ) : (
                      "No hay archivo disponible"
                    )}
                  </td>
                  <td className="py-2 px-4 border text-center">{order.amount}</td>
                  <td className="py-2 px-4 border text-center">{order.status}</td>
                  <td className="py-2 px-4 border text-center">{order.observation}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {/* Paginación */}
        <div className="flex justify-center items-center mt-4 w-full right-auto gap-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="w-10 h-10 flex items-center justify-center transition-colors bg-[#7C7980] hover:bg-[#645E69] disabled:bg-[#A59DAC] disabled:hover:bg-[#A59DAC] rounded-md text-white disabled:cursor-not-allowed"
          >
            {/* <i className="las la-chevron-circle-left text-[30px]"></i> */}
            <i className="las la-angle-left"></i>
          </button>
          {Array.from({ length: Math.ceil(totalRows / ordersPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`border border-none hover:bg-[#a764e0] hover:style_shadowInner__Oo-V8 hover:text-white w-10 h-10 flex items-center justify-center rounded-md text-white transition-colors duration-300 ${currentPage === index + 1
                  ? 'bg-[#a764e0] text-white'
                  : 'bg-transparent text-gray-600'
                }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * ordersPerPage >= totalRows}
            // className="text-[#6625EC] disabled:text-[#ab81ff] disabled:cursor-not-allowed"
            className="w-10 h-10 flex items-center justify-center transition-colors bg-[#7C7980] hover:bg-[#645E69] disabled:bg-[#a59dac] disabled:hover:bg-[#a59dac] rounded-md text-white disabled:cursor-not-allowed"
          >
            {/* <i className="las la-chevron-circle-right  text-[30px]"></i> */}
            <i className="las la-angle-right"></i>
          </button>
        </div>
        {/* <DataTable
          title="Cobros Solicitados"
          columns={columns}
          data={orders}
          selectableRows
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          // paginationPerPage={2}
          fixedHeader
          progressPending={loading}
          progressComponent={<Loader />}
          className="data-table"
          // theme="solarized"
          customStyles={{
            headRow: {
              style: {
                background: 'linear-gradient(to right, #653EFF, #6800D1, #3A0C7F)',
              }
            },
            table: {
              style: {
                borderRadius: '8px',
              }
            },
            headCells: {
              style: {
                color: '#fff', // Color del texto para las celdas del encabezado
              }
            },
            cells: {
              style: {
                color: '#4d4d4d', // Color del texto para las celdas de datos
              }
            }
          }}
        /> */}

      </div>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Solicitar Cobro"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Solicitar Cobro</h2>
        <p>Aquí va el contenido del modal.</p>
        <button onClick={closeModal} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
          Cerrar
        </button>
      </Modal> */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Solicitar Cobro"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header border-b border-[#c6c6c6] px-5 py-4">
          <h2 className="modal-title">SOLICITAR COBRO DE COMISIÓN</h2>
        </div>
        <div className="modal-body">
          <p className="modal-subtitle">Número de RUC: {ruc}</p>
          <p className="modal-subtitle">Monto disponible (Incluido IGV): S/ {pendingPaymentCommission}</p>
          <form className="modal-form">
            <div className="form-group flex gap-2 sm:gap-4 flex-col sm:flex-row">
              <div className="flex flex-col flex-[.5]">
                <label>Monto a solicitar <span className="text-red-500">*</span></label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.00"
                  onChange={handleMontoChange}
                />
              </div>
              <div className="flex flex-col flex-1">
                <label>Concepto</label>
                <input
                  type="text"
                  className="form-control"
                  value={concepto}
                  onChange={handleConceptoChange}
                />
              </div>
            </div>
            <div className="sm:flex">
              <div className="form-group flex-[.7]">
                <label>Solicitud de Pago <span className="text-red-500">*</span></label>
                <input value="" type="file" className="form-control" accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
                <small>Peso Max. 500KB - Formato "jpeg/png/jpg" - Tamaño 1000px X 800px</small>
              </div>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Imagen seleccionada"
                  className="max-w-[8rem] max-h-[8rem] m-auto object-cover z-0 flex-[.3]"
                />
              )}
            </div>

            <div className="form-group">
              <label>Solicitar Cobro</label>
              <div className="flex gap-8 mt-2">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="sinFactura"
                    name="cobro"
                    value="sinFactura"
                    onChange={handleCobroChange}
                    checked={cobro === 'sinFactura'}
                  />
                  <label htmlFor="sinFactura">SIN FACTURA</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="conFactura"
                    name="cobro"
                    value="conFactura"
                    onChange={handleCobroChange}
                    checked={cobro === 'conFactura'}
                  />
                  <label htmlFor="conFactura">CON FACTURA</label>
                </div>
              </div>
            </div>
          </form>

          <div className="info-section">
            <p><strong>¡Importante!</strong> La comisión solicitada será depositada en la siguiente Cuenta Bancaria.</p>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <p><strong>BANCO:</strong> {bank||'No registrado'}</p>
            <p><strong>NRO CTA:</strong> {accountNumber||'No registrado'}</p>
            <p><strong>NRO CTA CCI:</strong> {cciAccountNumber||'No registrado'}</p>
            <p><strong>Nombre del Titular:</strong> {holderName||'No registrado'}</p>
          </div>
        </div>

        <div className="modal-footer border-t border-[#c6c6c6] ">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Cerrar
          </button>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={handleRegisterCommissionButtonClick}
          >
            Solicitar Comisión
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Commissions;
